import { ModalButton } from 'src/design-system'
import * as React from 'react'
import { closeModal } from '../../utils/close-modal'
import { PositionModalProps, POSITION_MODAL_ID } from './utils'
import { store } from 'store/index'
import { trackEvent } from '../../services/event-tracker'

export type PositionModalButtonProps = {
  children: React.ReactNode
  className?: string
} & PositionModalProps

export const PositionModalButton: React.VFC<PositionModalButtonProps> = (
  props
) => {
  const { className, children, positionId, disciplineId, source } = props

  const currentUser = store.nonNullCurrentUser
  const hasActiveSubscription = !!currentUser.org?.activeSubscription
  const modalId = hasActiveSubscription ? POSITION_MODAL_ID : ''

  const onClick = () => {
    closeModal()
    trackEvent('$track_position_modal_opened', { source })

    if (hasActiveSubscription) return

    window.location.href = currentUser.isAdmin
      ? `/orgs/${currentUser.org?.slug}/billing/new`
      : '/'
  }

  return (
    <ModalButton
      className={className}
      modalId={modalId}
      modalProps={{ disciplineId, positionId, source }}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      {children}
    </ModalButton>
  )
}
